// TODO: there are many implicit operations here such as a stream set being tied to a route change
// break apart the logic in to discrete functions and check for any redundant rendering/ticks
// moreover, some of the implict stuff with routing and streaming could be formalized into mb a custom hook or something on the redux side of things
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import { graphql, Link, navigate, PageProps } from 'gatsby';
import { Heading, Flex, Para, Button, Label } from 'workspace-core-ui';
import Layout from '@containers/Layout';
import BodyWrapper from '@components/BodyWrapper';
import { setHeaderType } from '@slices/gameStateSlice';
import useTranslation from '@hooks/useTranslation';
import useSound from '@hooks/useSound';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import { ProfileQuestionData, Content, ControlData } from 'types';
import { logItem } from '@slices/loggingSlice';
import Seo from '@containers/Seo';
import BackgroundImage from '@components/BackgroundImage';
import ControlCenter from '@components/controls/ControlCenter';
import routeMap from '@content/routemap.json';
import { COUNTABLE_SCREENS, WINDOW_HASH } from '@sharedConstants';
import {
  initializeRoutes,
  setCurrentRoute,
  setCurrentStream,
} from '@slices/routeSlice';
import { setTotalClaims } from '@slices/levellingSlice';
import gameConfig from '@content/gameconfig';
import useNavigateLog from '@hooks/useNavigateLog';
import getSymbol from '@utils/getSymbol';

interface ProfileContext {
  url: string;
  screenData: ProfileQuestionData<
    Content,
    ProfileQuestionData[],
    ControlData<Content[]>
  >;
}

const ProfileQuestion = (
  props: PageProps<Queries.ProfileQuestionPageQuery, ProfileContext>,
) => {
  let mappedControlSubmitActions = useState<(() => void | undefined)[]>([]);
  const backgroundSymbol = getSymbol(props.data.profilePageBackgroundSymbol);
  const { allRoutes, currentStream } = useAppSelector(state => state.route);
  const [startTimestamp, setStartTimestamp] = useState<number>();
  const { playSound } = useSound();
  const dispatch = useAppDispatch();
  const { Controls, Name, Question_Text, Links_To } =
    props.pageContext.screenData;
  const { url } = props.pageContext;
  const { t, g } = useTranslation();

  useNavigateLog({ questionName: Question_Text.Content_Type });

  const questionAction = n => () => {
    // we need to infer the route by combining node terms, this is bad
    const { url: nextQuestionUrl } = routeMap.Splitter.find(
      e => e.name === `Splitter~${n.Name}~${n.Controls.Control_Type}`,
    );
    navigate(nextQuestionUrl + WINDOW_HASH || `/${WINDOW_HASH}`, {
      replace: true,
    });
  };

  useLayoutEffect(() => {
    // this is more for testing
    dispatch(setCurrentRoute({ compareAgainst: url }));
    // we want to initialize routes anyways here, in case user hits the skip button
    // TODO: might be redundant to do this
    dispatch(
      initializeRoutes({
        routes: routeMap[gameConfig.Stream_Table_Names[0]],
      }),
    );
    dispatch(
      setTotalClaims({
        newTotalClaims: routeMap[gameConfig.Stream_Table_Names[0]].filter(e =>
          COUNTABLE_SCREENS.includes(e.typeOfScreen),
        ).length,
      }),
    );
  }, [dispatch]);

  const streamRoutesAction = (newRoutes, streamName) => {
    dispatch(
      setCurrentStream({
        streamName,
      }),
    );
    dispatch(
      initializeRoutes({
        routes: newRoutes,
        currentStreamName: streamName,
      }),
    );
    dispatch(
      setTotalClaims({
        newTotalClaims: newRoutes.filter(e =>
          COUNTABLE_SCREENS.includes(e.typeOfScreen),
        ).length,
      }),
    );
  };
  // we need to shallowly dig into our object, deciding on steps based on the type of link present, we return an array of actions that are indexed against the "possible answer" given
  const getActionsArray = (): Array<(() => void) | undefined> => {
    // our starting node is always question (it must be) and its possible answers are directly correlated to the links_to array. Binary is bound to [0, 1]th indices, and likerts are bound [0, x] indices
    if (Controls?.Possible_Answers.length !== Links_To?.length) {
      throw new Error(
        'Possible answers do not correlate to the links_to array, cannot assign actions without parity',
      );
    }

    // while our data can be recursive, we only check one layer (the content editor must follow these rules)
    return Links_To.map(node => {
      // ALL nodes in this links array MUST of of type connector, otherwise throw
      if (node.Type_Of_Node === 'Connector') {
        // a connector node is always followed by either another question or a streamer. Return correct action accordingly.
        // a connectors links_to must be an object
        const connectorNodeLink = node.Links_To;
        if (connectorNodeLink.Type_Of_Node === 'Question') {
          return questionAction(connectorNodeLink);
        }

        if (connectorNodeLink.Type_Of_Node === 'Streamer') {
          return () => {
            const streamRoutes = routeMap?.[connectorNodeLink.Survey];
            if (streamRoutes) {
              streamRoutesAction(streamRoutes, connectorNodeLink.Survey);
            } else {
              console.error(
                `Your streamer node does not point to a valid stream name`,
                connectorNodeLink,
              );
            }
          };
        }
      } else {
        throw new Error(
          'invalid linking structure in profile question. Links must be connectors, or nothing returned',
        );
      }
    });
  };
  mappedControlSubmitActions = getActionsArray();

  useLayoutEffect(() => {
    // record when user officially "sees" the question
    setStartTimestamp(Date.now());
  }, []);

  useEffect(() => {
    dispatch(setHeaderType({ headerType: 'minimal' }));
  }, [dispatch, url]);

  useEffect(() => {
    // this is only for when we are streaming to a game, so we check if we have stream name set (and therefore must also have routes set assoc with that name)
    if (currentStream) {
      // to prevent double nav if the next route is another question
      navigate(allRoutes[0].url + WINDOW_HASH || `/${WINDOW_HASH}`, {
        replace: true,
      });
    }
  }, [allRoutes, currentStream]);

  const handleLinkedSubmit = e => {
    const submittedAnswer = e;
    playSound('Button');
    if (submittedAnswer) {
      const endTimestamp = Date.now();
      dispatch(
        logItem({
          result: '',
          question_name: Name,
          question_type: Controls?.Control_Type || '',
          collection_name: 'answers',
          answer_text: submittedAnswer.controlValue.toString(),
          duration_in_seconds: startTimestamp
            ? (endTimestamp - startTimestamp) / 1000
            : 0,
        }),
      );
      // run whatever action corresponds to the input value
      // our control value needs to be offset by 1 because it won't ever give us 0 when its a likert (to make it play nice with humans and the api)
      const ans = submittedAnswer.controlValue;
      const actionIndex = (() => {
        if (typeof ans == 'boolean') {
          return submittedAnswer.controlValue ^ 0;
        }
        if (typeof ans == 'number') {
          return (submittedAnswer.controlValue - 1) ^ 0;
        }
        if (typeof ans == 'string') {
          return Controls?.Possible_Answers.findIndex(
            e => e.Content_Type === ans,
          );
        }
      })();
      mappedControlSubmitActions[actionIndex]();
    }
  };

  return (
    <Layout data-cy="profileQuestion">
      {backgroundSymbol && (
        <BackgroundImage
          imageData={backgroundSymbol.data}
          imageType={backgroundSymbol.type}
        />
      )}
      <BodyWrapper p={5}>
        <Flex flex={0.5} />
        <Flex flexDirection="column" maxWidth="1100px" alignSelf="center">
          <Heading mb={4} variant="h2">
            <CustomMdxRenderer>
              {g(Controls?.Header_Text, true)}
            </CustomMdxRenderer>
          </Heading>
          <Para variant="p1">
            <CustomMdxRenderer>{g(Question_Text, true)}</CustomMdxRenderer>
          </Para>
        </Flex>
        <Flex mt="auto" mb={6} flexDirection="column" justifyContent="center">
          <ControlCenter
            typeOfControl={Controls?.Control_Type}
            setSubmittedAnswer={handleLinkedSubmit}
            possibleAnswers={Controls?.Possible_Answers}
          />
          {/* NOTE: escape hatch for user to just go directly to default stream */}
          {!Controls?.Is_Required && (
            <Button
              buttonSize="medium"
              variant="ghost"
              as={Link}
              onPress={() => {
                const endTimestamp = Date.now();
                playSound('Button');
                dispatch(
                  logItem({
                    question_name: Name,
                    question_type: Controls?.Control_Type || '',
                    result: '',
                    collection_name: 'answers',
                    answer_text: 'skip',
                    duration_in_seconds: startTimestamp
                      ? (endTimestamp - startTimestamp) / 1000
                      : 0,
                  }),
                );
              }}
              mt={7}
              mx="auto"
              to={allRoutes[0]?.url + WINDOW_HASH || `/${WINDOW_HASH}`}
              replace
            >
              <Label textDecoration="underline" variant="l3">
                {t('Skip Button')}
              </Label>
            </Button>
          )}
        </Flex>
      </BodyWrapper>
    </Layout>
  );
};

export const query = graphql`
  query ProfileQuestionPage {
    profilePageBackgroundSymbol: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "Claim Background Image" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }
  }
`;

export default ProfileQuestion;

export const Head = () => <Seo />;
